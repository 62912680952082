<template>
  <div class="footer">
    <div class="main">
      <div class="logocontent">
        <div class="footer-logo">
          <img src="../assets/img/yimo-img/logoMain.png" alt="" />
        </div>
      </div>
      <div class="msg">
        <div class="leftmsg">
          <p>广西南宁大都网络科技有限公司©️版权所有</p>
          <a class="beian" href="https://beian.miit.gov.cn"> <p>桂ICP备2022000744号-5</p></a>
        </div>
        <div class="rightmsg">
          <p>联系邮箱：bgaqzx@chinaesport.com</p>
          <p>地址：中国（广西）自由贸易试验区南宁片区庆林路16号天誉花园4号地块5号楼三层3591号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import url('../assets/css/base.less');
.mb16 {
  margin-bottom: 16px;
}

.scrollbottom {
  display: block;
  min-width: 1300px;
  .footer {
    width: 100vw;
    height: 154px;
    padding-left: 202px;
    padding-bottom: 11px;
    background-color: rgba(9, 92, 249, 1);
    // display: flex;
    // align-items: center;
    z-index: 1;
    // justify-content: center;
    // min-width: 1300px;
    .main {
      // width: 1120px;
      height: 100%;
      display: flex;
      // min-width: 1300px;
      flex-direction: column;
      .logocontent {
        height: 73px;
        width: 104px;
        // width: fit-content;
        position: relative;
        margin-bottom: 8px;
        .footer-logo {
          width: 21.86%;
          position: absolute;
          bottom: 0;
          img {
            width: 127px;
            height: 28px;
          }
        }
      }
      .msg {
        //   scale: 0.8;
        height: 29.2%;
        width: 77%;
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        font-size: 8px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 100;
        color: #ffffff;
        line-height: 22px;
        .leftmsg,
        .rightmsg {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .mainLeft {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content {
          height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: #4430b4;
          .logo {
            height: 64px;
            img {
              height: 100%;
            }
          }
        }
      }

      .mainRight {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #4430b4;
        .content {
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          p {
            margin-top: 12px;
          }
        }
      }
    }
  }
}
.beian {
  text-decoration: none;
  color: #fff;
  width: 154px !important;
}
.beian:hover {
  color: #fff;
}
</style>
